import PropTypes from 'prop-types'
import React from 'react'
import { Section, Columns, Container } from 'react-bulma-components'

// Components
import ClientQuote from 'components/client-quote'
import Separator from 'components/separator'

function Testimonials({ testimonials }) {
  return (
    <Section className="is-small is-hidden-mobile has-background-grey-lighter">
      <Container>
        <Columns className="mt-6 mb-6">
          {testimonials.edges.map(({ node: testimonial }) => (
            <Columns.Column
              key={testimonial.id}
              className="is-4-desktop is-10-tablet is-12-mobile has-text-centered content mt-5"
            >
              <ClientQuote testimonial={testimonial} key={testimonial.id} />
            </Columns.Column>
          ))}
        </Columns>
      </Container>
    </Section>
  )
}
Testimonials.propTypes = {
  testimonials: PropTypes.object,
}
export default Testimonials
