import React from 'react'
import { Link } from 'gatsby'
import { Section, Columns, Container } from 'react-bulma-components'

function PeopleFIrst() {
  return (
    <Section className="mt-0">
      <Container>
        <Columns className=" is-mobile">
          <Columns.Column className="is-offset-1-desktop is-4-desktop is-4-tablet is-hidden-mobile content">
            <img
              src="https://www.datocms-assets.com/20955/1579120467-speedupillustrationpocketworks.png?fm=jpg&w=500&bg=FAF7F2"
              alt=""
            />
          </Columns.Column>

          <Columns.Column className="is-6-desktop is-6-tablet is-12-mobile content ">
            <h2>
              People first, then <span className="">tech.</span>
            </h2>
            <p className="">
              Building your MVP is not just about cranking out code. Great
              products come from determination, dependability and healthy
              communications. That's why we start by establishing cultural fit.
              If we have that right, you'll notice everything moves faster and
              you'll always know where you stand.
            </p>
            <p>
              <Link to="/contact" className="button is-leafy">
                Let's talk
              </Link>
              .
            </p>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default PeopleFIrst
