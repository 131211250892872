import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Card, Columns, Container } from 'react-bulma-components'
import { GatsbyImage } from 'gatsby-plugin-image'

function ClientQuote({ testimonial }) {
  return (
    <Card className="has-text-left p-6 has-background-white is-equal-height content">
      <Columns className="is-mobile">
        <Columns.Column className="is-3-widescreen is-4-desktop is-3-tablet">
          <GatsbyImage
            className={`${testimonial.isFlipped ? 'flipped' : ''} is-rounded`}
            image={testimonial.clientImage.gatsbyImageData}
            alt={
              testimonial.clientImage.alt || 'Pocketworks Testimonial Client'
            }
          />
        </Columns.Column>
        <Columns.Column className="is-8">
          <h4 className="mb-0">{testimonial.clientName}</h4>
          <p className="is-size-6">{testimonial.clientRole}</p>
          {testimonial.caseStudy != null ? (
            <Link
              className=""
              to={`/case-studies/${testimonial.caseStudy.slug}`}
            >
              <GatsbyImage
                image={testimonial.clientLogo.gatsbyImageData}
                alt={
                  testimonial.clientLogo.alt || 'Pocketworks Client Identity'
                }
              />
            </Link>
          ) : (
            <GatsbyImage
              image={testimonial.clientLogo.gatsbyImageData}
              alt={testimonial.clientLogo.alt || 'Pocketworks Client Identity'}
            />
          )}
        </Columns.Column>
      </Columns>
      <p className="testimonial-quote p-3">{testimonial.content}</p>
    </Card>
  )
}

ClientQuote.propTypes = {
  testimonial: PropTypes.object,
}

export default ClientQuote
