import PropTypes from 'prop-types'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

function Separator({ className, rtl }) {
  return <div />
  // eslint-disable-next-line no-unreachable
  // if (rtl) {
  //   return (
  //     <div className={className || ''}>
  //       <StaticImage
  //         src="https://www.datocms-assets.com/20955/1579120569-link-reversed.png?w=1800"
  //         alt="separator"
  //         className="separator"
  //         placeholder="blurred"
  //         quality={100}
  //       />
  //     </div>
  //   )
  // }

  // return (
  //   <div className={className || ''}>
  //     <StaticImage
  //       src="https://www.datocms-assets.com/20955/1579120592-link.png?w=1800"
  //       alt="separator"
  //       className="separator"
  //       placeholder="blurred"
  //       quality={100}
  //     />
  //   </div>
  // )
}

Separator.propTypes = {
  className: PropTypes.string,
  rtl: PropTypes.bool,
}

export default Separator
