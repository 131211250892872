import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Section, Columns, Container, Hero } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import WorkNav from 'components/work-nav'
import ArrowLink from 'components/link-with-arrow'
import Layout from 'components/layout'
import Clients3 from 'components/clients3'
import TobinQuote from 'components/tobinQuote'
import Customers from 'components/customers'
import CaseStudyBlog from 'components/casestudyBlog'
import Testimonials from 'components/testimonials'
import WorkHero from 'components/workHero'

// Page Components
import PeopleFIrst from './components/people-first'

function Startups({ data: { page, caseStudies, testimonials } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <WorkNav />
      <WorkHero page={page} />
      <hr />
      <PeopleFIrst />
      <Testimonials testimonials={testimonials} />
      <Hero className="">
        <Hero.Body>
          <Container className="content">
            <Columns className="is-centered">
              <Columns.Column className="is-10 has-text-centered">
                <h2 className="is-size-3-mobile">
                  Hire your{' '}
                  <span className="has-text-info">
                    digital product team as-a-service
                  </span>
                </h2>
                <p>
                  You can ask us to help out before or after your MVP is built.
                  <br />
                  You pay on a rolling basis with a minimum period of just 3
                  months.
                  <br />
                  Your founders will have plenty of time to get to know us
                  before you commit.
                </p>
                <br />
                <br />
              </Columns.Column>
            </Columns>
            <Columns className="is-centered">
              <Columns.Column className="is-3">
                <h3>Learn</h3>
                <p>From £10k per month</p>
                <p>Figure out what the right thing to build.</p>
                <ul>
                  <li>Research user needs</li>
                  <li>Create concepts</li>
                  <li>Validate them</li>
                  <li>Plan your MVP</li>
                </ul>
                <p>
                  <small className="has-text-grey">
                    Take a user-centric approach to design that includes
                    iterative research, prototyping and testing.
                  </small>
                </p>
              </Columns.Column>
              <Columns.Column className="is-3">
                <h3>Launch</h3>
                <p>From £24k per month</p>
                <p>Get the first version of your product out there.</p>
                <ul>
                  <li>Develop your MVP</li>
                  <li>Capture AARRR metrics</li>
                  <li>Test and release often</li>
                  <li>Apps, web portals and data</li>
                </ul>
                <p>
                  <small className="has-text-grey">
                    Create portals, web sites, Flutter apps, iOS and Android
                    apps or database systems. All tested by us.
                  </small>
                </p>
              </Columns.Column>
              <Columns.Column className="is-3">
                <h3>Grow</h3>
                <p>From £8k per month</p>
                <p>Improve your product whilst keeping it up and running.</p>
                <ul>
                  <li>Maximise retention</li>
                  <li>Develop new features</li>
                  <li>Get user feedback </li>
                  <li>Monitoring and support</li>
                </ul>
                <p>
                  <small className="has-text-grey">
                    Benefit from a continuous approach to learning from data,
                    adding or removing features and keeping your apps healthy.
                  </small>
                </p>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column className="is-offset-2 is-8 has-text-centered">
                <p>
                  <br />
                  <Link
                    to="/contact"
                    className="button is-primary is-outlined is-leafy is-medium is-primary"
                  >
                    Let's talk about your startup
                  </Link>
                </p>
              </Columns.Column>
            </Columns>
          </Container>
        </Hero.Body>
      </Hero>
      <Clients3 />
      <TobinQuote />
      <CaseStudyBlog caseStudies={caseStudies} />
      <Customers />
      <Section>
        <Container className="has-text-centered">
          <ArrowLink to="/work/services" className="is-info">
            Next: Services &amp; Packages
          </ArrowLink>
        </Container>
      </Section>
    </Layout>
  )
}

Startups.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Startups
